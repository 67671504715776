import React from 'react';
import { AppBar, Box, Button, Container, IconButton, Menu, MenuItem, ListItemIcon, Select, Toolbar, useTheme, Typography, useMediaQuery } from '@mui/material';
import { Home, Info, Menu as MenuIcon, QuestionMark, Star } from '@mui/icons-material';
import { useI18next } from 'gatsby-plugin-react-i18next';
import LogoImage from '../images/logo.svg';
import LogoTextImage from '../images/logo-text.svg';
import FlagGermanyImage from '../images/flag-germany.svg';
import FlagGreatBritainImage from '../images/flag-great-britain.svg';
import { Link } from 'gatsby-link';

export default function Header() {
  const { t, originalPath, i18n, changeLanguage } = useI18next();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const pages = [
    {
      name: 'Home',
      icon: <Home />,
      href: '/',
    },
    {
      name: 'Build',
      icon: <Star />,
      href: '/build',
    },
    {
      name: 'FAQ',
      icon: <QuestionMark />,
      href: '/faq',
    },
    {
      name: 'About us',
      icon: <Info />,
      href: '/about-us',
    },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleChangeLanguage = (language: string) => {
    changeLanguage(language);
  };

  const fontColor = 'rgba(0, 0, 0, 0.8)';
  const iconColor = theme.palette.primary.main;

  return (
    <AppBar position="relative" color="transparent">
      <Container>
        <Toolbar>
          <Box display="flex" flexGrow={0} alignItems="center" gap={1} p={1}>
            <img src={LogoImage} width="48" height="48" />
            <img src={LogoTextImage} width="100" height="48" />
          </Box>
          <Box flexGrow={1} display={{ xs: "none", md: "flex" }} justifyContent="center" gap={2}>
            {pages.map((page, index) =>
              <React.Fragment key={page.name}>
                <Link to={page.href}>
                  <Button sx={{ color: fontColor }} startIcon={page.icon}>
                    {t(page.name)}
                  </Button>
                </Link>
              </React.Fragment>
            )}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              sx={{ color: fontColor }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <Link key={page.name} to={page.href}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Button startIcon={page.icon}>
                      {t(page.name)}
                    </Button>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>

          <Box display="flex" alignItems="center" gap={2} sx={{ flexGrow: 0 }}>
            {isDesktop &&
              <Box>
                <Typography fontSize="0.6rem">
                  {t('QUESTIONS')}?
                </Typography>
                <a href="mailto:info@livyup.com">
                  <Typography fontSize="0.8rem">info@livyup.com</Typography>
                </a>
              </Box>
            }

            <Select value={i18n.language} sx={{ 'fieldset': { border: 'none' } }} onChange={(event) => handleChangeLanguage(event.target.value)}>
              <MenuItem value="de">
                <ListItemIcon sx={{ minWidth: '20px' }}>
                  <img src={FlagGermanyImage} width="20" height="12" />
                </ListItemIcon>
              </MenuItem>
              <MenuItem value="en">
                <ListItemIcon sx={{ minWidth: '20px' }}>
                  <img src={FlagGreatBritainImage} width="20" height="12" />
                </ListItemIcon>
              </MenuItem>
            </Select>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
