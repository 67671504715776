import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CookieConsent as ReactCookieConsent } from 'react-cookie-consent';

export default function CookieConsent() {
  const { t } = useTranslation();

  const handleCookiesAccept = () => {

  };

  const handleCookiesDecline = () => {

  };

  return (
    <ReactCookieConsent
      enableDeclineButton
      buttonText={t('Accept')}
      declineButtonText={t('Decline')}
      onAccept={handleCookiesAccept}
      onDecline={handleCookiesDecline}
    >
    
      <Typography>
        {t('COOKIE_INFO')}
      </Typography>
    </ReactCookieConsent>
  )
}