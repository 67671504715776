import React from 'react';
import { ThemeProvider, createTheme} from '@mui/material';
import { Provider as ReduxProvider } from 'react-redux'
import { initializeApp } from '@firebase/app';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, StorageProvider } from 'reactfire';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { getAuth } from '@firebase/auth';
import CookieConsent from '../components/CookieConsent';
import Header from '../components/header';
import Footer from '../components/footer';
import '@fontsource/inter/variable.css';
import { store as reduxStore } from '../state/store';
import '../styles/global.scss';

const firebaseConfig = {
  apiKey: 'AIzaSyBNfwyD9dzKMs7U2OFtIzXmZaCPTXVB-2E',
  authDomain: 'livyup.firebaseapp.com',
  projectId: 'livyup',
  storageBucket: 'livyup.appspot.com',
  messagingSenderId: '263296523656',
  appId: '1:263296523656:web:5e43b6a2333490c6102e3d',
  measurementId: 'G-3Z8YNJMZ3M',
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseFirestore = getFirestore(firebaseApp);
const firebaseStorage = getStorage(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 150, 50)',
    },
    secondary: {
      main: 'rgb(150, 0, 100)',
    },
  },
  typography: {
    fontFamily: 'InterVariable',
    fontWeightRegular: 400,
  },
});

export default function Layout({ children }: React.PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <FirebaseAppProvider firebaseApp={firebaseApp}>
        <FirestoreProvider sdk={firebaseFirestore}>
          <StorageProvider sdk={firebaseStorage}>
            <AuthProvider sdk={firebaseAuth}>
              <ReduxProvider store={reduxStore}>
                <CookieConsent />
                <Header />
                <main>
                  {children}
                </main>
                <Footer />
              </ReduxProvider>
            </AuthProvider>
          </StorageProvider>
        </FirestoreProvider>
      </FirebaseAppProvider>
    </ThemeProvider>
  );
}
