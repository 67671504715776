import React from 'react';
import { Button, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-link';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Grid container justifyContent="space-evenly" alignItems="center" sx={{ height: '100%' }}>
          <Grid>
            <Link to="/impressum">
              <Button sx={{ color: 'white' }}>
                {t('Impressum')}
              </Button>
            </Link>
          </Grid>
          <Grid>
            <Link to="/tos">
              <Button sx={{ color: 'white' }}>
                {t('TOS')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
