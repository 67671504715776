import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DeliveryFlavor, DeliveryForm, getOrderSummary, Ingredient, Order, ServingsCount, SubscriptionDays } from '../../functions/src/models';
import { Config } from '../../functions/src/models/Config';
import { RootState, useAppSelector } from './store';

export interface OrderDetails {
  customerFirstName?: string;
  customerLastName?: string;
  customerEmail?: string;
  orderReference?: string;
};

// Define a type for the slice state
type State = Order & {
  activeStep: number;
} & {
  orderDetails?: OrderDetails;
} & {
  newsletter: boolean;
};

// Define the initial state using that type
const initialState: State = {
  ingredients: [],
  deliveryForm: 'powder',
  servingsCount: 30,
  subscriptionDays: 30,
  flavor: 'none',
  discountCode: '',
  languageCode: 'de', // TODO: Set this during checkout.
  activeStep: 0,
  newsletter: false,
};

export const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addIngredient: (state, action: PayloadAction<{ name: string, byAmount: number }>) => {
      const selectedIngredientIndex = state.ingredients.findIndex((value) => value.name == action.payload.name);
      if (selectedIngredientIndex < 0) {
        if (action.payload.byAmount > 0) {
          state.ingredients.push({
            name: action.payload.name,
            amount: action.payload.byAmount,
          });
        }
      } else {
        const selectedIngredient = state.ingredients[selectedIngredientIndex];
        selectedIngredient.amount += action.payload.byAmount;
        if (selectedIngredient.amount <= 0) {
          state.ingredients.splice(selectedIngredientIndex, 1);
        }
      }
    },
    setDeliveryForm: (state, action: PayloadAction<DeliveryForm>) => {
      state.deliveryForm = action.payload;
    },
    setServingsCount: (state, action: PayloadAction<ServingsCount>) => {
      state.servingsCount = action.payload;
    },
    setSubscriptionDays: (state, action: PayloadAction<SubscriptionDays>) => {
      state.subscriptionDays = action.payload;
    },
    setFlavor: (state, action: PayloadAction<DeliveryFlavor>) => {
      state.flavor = action.payload;
    },
    setDiscountCode: (state, action: PayloadAction<string>) => {
      state.discountCode = action.payload;
    },
    setLanguageCode: (state, action: PayloadAction<string>) => {
      state.languageCode = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setOrderDetails: (state, action: PayloadAction<OrderDetails>) => {
      state.orderDetails = action.payload;
    },
    setNewsletter: (state, action: PayloadAction<boolean>) => {
      state.newsletter = action.payload;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;
export const { reducer } = slice;

export const useOrder = () => useAppSelector((state: RootState) => state.order);

export default reducer;
